<template>
  <div class="background-main">
    <div class="local-loader-container" v-if="loader">
      <div class="inava-loader"></div>
    </div>
    <header-vue>
      <div class="page-label">
        <p>{{ active }}</p>
      </div>
    </header-vue>
    <div class="seperate-panel">
      <div class="flex-three">
        <side-panel-vue>
          <side-panel-list
          initial="admin"
          :list="customList" />
        </side-panel-vue>
      </div>
      <div class="flex-seven">
        <div class="main-view">
          <div class="padding-both-side">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SidePanelList from '../../../../components/Admin/components/SidePanelList.vue'
import SidePanelVue from '../../../../components/Common/SidePanel/SidePanel.vue'
import HeaderVue from '../../../../components/Header/Header.vue'
import { adminPanelList } from '../../../../util/util'

/** This component contains the text for the admin panel dashboard */
export default {
  name: 'Dashboard',
  components: {
    SidePanelVue,
    HeaderVue,
    SidePanelList
  },
  data () {
    return {
      adminPanelList,
      active: null,
      list: adminPanelList
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('adminCommon', ['tableSelectionState', 'loader']),
    customList () {
      const newList = []
      for (const permission of adminPanelList) {
        const res = this.userDetails.permissions.filter((data) => data.path === permission.name && data.read)
        if (res.length) {
          newList.push(permission)
        }
      }
      if (newList.length) { newList.unshift(adminPanelList[0]) }
      const listData = []
      const listSubMenu = []
      const obj = {}
      newList.forEach((element) => {
        if (element.subMenu === false) {
          listData.push(element)
        } else {
          listSubMenu.push(element)
        }
      })
      if (listSubMenu.length > 0) {
        obj.categories = listSubMenu
        obj.subMenu = true
      }
      listData.push(obj)
      return listData
    }
  },
  watch: {
    $route (to, from) {
      if (to !== from) {
        this.getPageName(to.name)
      }
    }
  },
  mounted () {
    this.active = this.$route.name
    this.getPageName(this.$route.name)
  },
  methods: {
    /** This method gets the name of the current page
     * @public
     */
    getPageName (name) {
      const res = adminPanelList.filter((obj) => obj.path === name)
      if (res.length) {
        this.active = res[0].alias
      } else {
        this.active = null
      }
    }
  }
}
</script>

<style lang="scss" src="../../../MainPage/main.scss" scoped></style>
