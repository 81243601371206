<template>
  <div class="menu-list-container">
    <div class="path-title">
      <p>{{ initial }}</p>
    </div>
    <div v-for="(details, index) of list" :key="index" class="button-each">
      <div v-if="details.subMenu && details.subMenu === true">
        <div class="toggle-field" @click="toggleMenu">
          <p>News</p>
          <span v-if="collapsed === false" class="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#7f8fa4" d="M6.718 3.184a.5.5 0 010 .707L3.89 6.719a.5.5 0 11-.707-.707l2.475-2.475-2.475-2.475A.5.5 0 113.89.355z"/></svg></span>
          <span v-else class="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg></span>
        </div>
        <div v-if="collapsed === true">
          <div
            v-for="(details, index) of details.categories"
            :key="index"
            class="button-each">
            <b-button
              :class="[active == details.path ? 'active' : '']"
              @click="changeRoute(details.path)">
              <p>{{ details.alias }}</p></b-button>
          </div>
        </div>
      </div>
      <div v-else>
        <b-button
          v-if="verifySSORoute(details)"
          :class="[active == details.path ? 'active' : '']"
          @click="changeRoute(details.path)">
          <p>{{ details.alias }}</p></b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SidePanelList',
  props: ['list', 'initial'],
  data () {
    return {
      active: null,
      collapsed: false
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  watch: {
    $route (to, from) {
      if (to !== from) {
        this.active = to.name
      }
    }
  },
  mounted () {
    this.active = this.$route.name
  },
  methods: {
    changeRoute (name) {
      this.$router.push(`/${this.initial}/` + name)
    },
    verifySSORoute ({ path }) {
      let value = false
      if ( path === 'security-and-permissions' ) {
        const permission = this.userDetails.permissions.find(
          (perm) => perm.path === 'security-and-permissions'
        )
        // If the permission for the given feature is not found, the user does not have permission for that feature
        if (!permission) {
          value = false
        }
        // Check if the user has permission for the specified action (read, write, update, delete)
        value = permission ? permission['write'] : false
      } else {
        value = true
      }
      return value
    },
    toggleMenu () {
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-list-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .path-title {
    padding-bottom: 10px;
    p {
      font-family: Quicksand;
      font-size: 14px;
      line-height: 2.3;
      color: #ffffff;
      text-transform: capitalize;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
  .button-each {
    .button {
      padding: 5px 0px;
      display: flex;
      justify-content: flex-start;
      border: none;
      border-radius: 0px;
      background-color: transparent;
      color: rgba(239, 243, 246, 0.7);
      height: 100%;
      width: 100%;
      box-shadow: none;
      p {
        font-size: 14px;
      }
      &:hover {
        color: #2780eb;
      }
    }
    .toggle-field{
      cursor: pointer;
      background-color: transparent;
      color: rgba(239, 243, 246, 0.7);
      display: flex;
      justify-content: flex-start;
      align-content: center;

      p{
        width: 70%;
      }
      span{
        width:30%;
        img{
          display: flex;
        flex-direction: column;;
        justify-content: center;
        align-content: center;

        }
      }
    }
    .arrow{
      img{
        height: 14px;
        width: 14px;
      }
    }
    .active {
      color: #2780eb;
    }
  }
}
</style>
